<template>
  <div class="preview-modal-container">
    <h3 v-if="hasTitle">Preview Only</h3>
    <v-sheet class="mx-auto pa-2" style="width: 100%">
      <div
        id="previewContainer"
        class="content-preview"
        :style="`
          background-color: ${itemData && itemData.backgroundColor};
          background-image: url(${itemData.graphic});
          background-size: cover;`"
      >
        <div
          id="logoContainer"
          :class="'overlayContainer ' + logoPositionClass()"
        >
          <img
            id="coachLogo"
            v-if="itemData.showLogo"
            :src="currentCoach.coachLogo || ''"
            class="logo-preview"
          />
        </div>
        <div
          id="itemTextContainer"
          :class="'overlayContainer ' + textPositionClass()"
        >
          <div
            id="itemText"
            v-html="preserveHTML()"
            :style="getTextPreviewStyle()"
            class="text-preview"
          ></div>
        </div>
      </div>
    </v-sheet>
  </div>
</template>
<script>
export default {
  props: {
    hasTitle: { type: Boolean, default: true },
    itemData: Object,
    currentCoach: Object,
  },
  methods: {
    getTextPreviewStyle() {
      return ` font-family: ${this.itemData.fontFamily}; font-size:${this.itemData.fontSize}`;
    },

    getLogoPreviewStyle() {
      return `background-image: url(${this.currentCoach?.coachLogo}); grid-area: ${this.itemData?.logoPositionY}${this.itemData?.logoPositionX};`;
    },
    logoPositionClass() {
    
      return `${this.itemData.logoPositionX} ${this.itemData.logoPositionY}`;
    },
    preserveHTML() {
      if (!this.itemData || !this.itemData.text) return "";
      return this.itemData.text.replaceAll("  ", "&nbsp;&nbsp;") || "";
    },
    textPositionClass() {
      return `${this.itemData.textPositionY}`;
    },
  },
  mounted() {
    console.log(
      this.itemData,
      this.itemData.logoPositionX + " " + this.itemData.logoPositionY
    );
  },
};
</script>
<style lang="scss" scoped>
.preview-modal-container {
  width: 435px;
  margin: 0 auto;
  max-height: 100%;
  margin-left: 50%;
  transform: translate(-50%, 0);
}
.content-preview {
  position: relative;
  min-width: calc(415px / 2);
  min-height: calc(750px / 2);
  width: 100%;
  max-width: 415px;
  height: 750px;
  padding: 5px;
  margin: auto;
}
.bg-preview {
  width: 100%;
  height: 100%;
  background-size: cover;
  z-index: 10;
}
.overlayContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 7px;
  display: flex;
  .text-preview {
    z-index: 50;
    display: block;
    width: 100%;
  }
}
.xLeft {
  justify-content: left;
}
.xCenter {
  justify-content: center;
}
.xRight {
  justify-content: right;
}
.yStart {
  align-items: flex-start;
}
.yCenter {
  align-items: center;
}
.yEnd {
  align-items: flex-end;
}

#logoContainer {
  padding: 3px 7px;
  flex-direction: row;

  .logo-preview {
    display: block;
    padding: 3px 2px;
    max-width: 100px;
    max-height: 150px;
    z-index: 100;
  }
}

#itemTextContainer {
  padding-right: 12px;
}

@media (max-width: 1024px) {
  #previewContainer {
    transform: scale(0.7) translate(-50%, 0);
    transform-origin: top left;
    margin-left: 50%;
  }
}
</style>
