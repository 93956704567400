<template>
  <div class="text-left">
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn elevation="0" color="lighten-2" dark v-bind="attrs" v-on="on">
          Edit
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Edit Deck
        </v-card-title>

        <v-form ref="form">
          <v-container>
            <v-row>
              <v-col cols="12" md="12">
                <v-text-field
                  type="text"
                  v-model="programData.name"
                  label="Program Name"
                  required
                  :rules="[(v) => !!v || 'Name is required']"
                ></v-text-field>
              </v-col>

              <!--  Active / Draft -->
              <v-col cols="12" md="12">
                <v-select
                  v-model="programData.status"
                  label="Status"
                  required
                  :rules="[(v) => !!v || 'Status is required']"
                  :items="[
                    { text: 'Draft', value: 'draft' },
                    { text: 'Active', value: 'active' },
                  ]"
                >
                </v-select>
              </v-col>

              <!-- Frequency Days Checkboxes -->
              <v-col cols="12" md="12">
                <div> Select Day(s) To Send:</div> <hr>
                <div   v-for="day in days"             :key="day.field" style="border: 0px solid black; padding:0px 3px;display: inline-block">
                 <v-checkbox v-model="programData[day.field]" :label="day.label">
                </v-checkbox>
              
                </div>
              </v-col>

              <!-- Day Selector -->

              <v-col
                cols="2"
                md="2"
              
              >
               
              </v-col>

              <!-- Time Selector -->
              <v-col cols="12" md="12">
                <input type="time" v-model="programData.frequencyTime" />
                <v-select
                v-model="programData.timezone"
                :items="[
                    { text: 'PST', value: '-7' },
                    { text: 'MST', value: '-6' },
                    { text: 'CST', value: '-5' },
                    { text: 'EST', value: '-4' },
                  ]"
                >

                </v-select>
              </v-col>

              <!-- MORE URL -->
              <v-col cols="12" md="12">
                
                 <v-text-field type="text" label="Learn More Link" v-model="programData.moreUrl" placeholder="https://yourwebsite.com"></v-text-field>
              </v-col>
            </v-row>


          </v-container>
        </v-form>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="secondary" text @click="cancel()"> Cancel </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="updateProgram()"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import gql from "graphql-tag";
import { mapActions } from "vuex";

export default {
  props: ["programId", "programData"],
  methods: {
    async updateProgram() {
      if (this.$refs.form.validate()) {
        const done = await this.$apollo.mutate({
          mutation: gql`
            mutation updateProgram($id: Float!, $data: ProgramInput!) {
              updateProgram(id: $id, data: $data) {
                id
              }
            }
          `,
          variables: {
            id: parseInt(this.programId),
            data: {
              name: this.programData.name,
              coachId: parseInt(this.programData.coach.id),
              frequency: this.programData.frequency || "daily",
              frequencyDetail: parseInt(this.programData.frequencyDetail),
              frequencyTime: this.programData.frequencyTime || "12:00",
              status: this.programData.status,
              fSunday: this.programData["fSunday"],
              fMonday: this.programData["fMonday"],
              fTuesday: this.programData["fTuesday"],
              fWednesday: this.programData["fWednesday"],
              fThursday: this.programData["fThursday"],
              fFriday: this.programData["fFriday"],
              fSaturday: this.programData["fSaturday"],
              moreUrl: this.programData['moreUrl'],
              timezone: this.programData['timezone']
            },
          },
        });
        await this.getProgram({ program: { id: this.programId } });
        if (done) {
          this.dialog = false;
          this.programName = null;
          this.frequency = null;
          this.frequencyDetail = null;
          this.frequencyTime = null;

          return true;
        }
      }
    },
    async cancel() {
      //Reset Fields
      await this.getProgram({ program: { id: this.programId } });
      this.dialog = false;
    },
    ...mapActions({
      getProgram: "axProgram",
    }),
  },
  computed: {
    frequencyDetailValues() {
      if (this.programData.frequency === "monthly")
        return [...Array(28).keys()].map((num) => num + 1);
      if (this.programData.frequency === "weekly") {
        return [
          { value: "1", text: "Monday" },
          { value: "2", text: "Tuesday" },
          { value: "3", text: "Wednesday" },
          { value: "4", text: "Thursday" },
          { value: "5", text: "Friday" },
          { value: "6", text: "Saturday" },
          { value: "0", text: "Sunday" },
        ];
      }
      return 0;
    },
  },
  data() {
    return {
      dialog: false,
      time: null,
      menu2: false,
      programName: "",
      frequency: null,
      frequencyValues: [
        { value: "daily", text: "Daily" },
        { value: "weekly", text: "Weekly" },
      ],
      frequencyDetail: 0,
      frequencyTimeValues: 0,
      days: [
        { field: "fSunday", label: "Sunday" },
        { field: "fMonday", label: "Monday" },
        { field: "fTuesday", label: "Tuesday" },
        { field: "fWednesday", label: "Wednesday" },
        { field: "fThursday", label: "Thursday" },
        { field: "fFriday", label: "Friday" },
        { field: "fSaturday", label: "Saturday" },
      ],
    };
  },
};
</script>
<style scoped>
 .d-flex{
   padding: 5px;
 }
</style>