<template>
  <v-dialog v-model="isOpen" @keydown.esc="cancel" :max-width="800">
    <v-sheet tile class="modal">
      <v-toolbar color="cyan" dark class="toolbar">
        <v-toolbar-title :style="{ width: '100%' }"> Preview </v-toolbar-title>
        <v-btn outlined tile @click="back">Back</v-btn>
        <v-btn outlined tile @click="next">Next</v-btn>
      </v-toolbar>
      <v-sheet class="p-5 content">
        <Previewer
          :hasTitle="false"
          :itemData="cardInfo"
          :currentCoach="currentCoach"
        />
      </v-sheet>
    </v-sheet>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Previewer from "../components/GraphicEditor/Previewer.vue";

export default {
  name: "PreviewDecksDialog",
  props: ["contentIds"],
  components: { Previewer },
  data() {
    return {
      isOpen: false,
      activeIndex: 0,
    };
  },
  computed: {
    ...mapGetters({
      currentCoach: "loggedInCoach",
      cardInfo: "getCurrentProgramItem",
    }),
  },
  methods: {
    open() {
      this.isOpen = true;
    },
    cancel() {
      this.dialog = false;
    },
    back() {
      if (this.activeIndex !== 0) {
        this.activeIndex--;
      } else {
        this.activeIndex = this.contentIds.length - 1;
      }
    },
    next() {
      if (this.activeIndex < this.contentIds.length - 1) {
        this.activeIndex++;
      } else {
        this.activeIndex = 0;
      }
    },
    ...mapActions({
      getCardInfo: "axProgramItem",
    }),
    getCard(data) {
      data.length > 0 &&
        this.getCardInfo({
          programItemId: data[this.activeIndex] * 1,
        });
    },
  },
  watch: {
    activeIndex: function (val) {
      this.getCardInfo({
        programItemId: this.contentIds[val] * 1,
      });
    },
    contentIds: function (val) {
      this.getCard(val);
    },
  },
  mounted() {
    this.getCard(this.contentIds);
  },
};
</script>

<style lang="scss" scoped>
.modal {
  position: relative;
  min-height: 500px;
}
.toolbar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
}

.content {
  height: 100%;
  padding-top: 64px;
  overflow: hidden;
}

.v-dialog {
  display: flex;
}

@media (max-width: 960px) {
  .content {
    padding-top: 56px;
  }
}
</style>
