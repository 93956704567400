<template>
  <v-dialog transition="dialog-bottom-transition" max-width="600">
    <template v-slot:activator="{ on, attrs }">
      <v-chip color="primary" v-bind="attrs" v-on="on" outlined small>
        ?
      </v-chip>
    </template>
    <template v-slot:default="dialog">
      <v-card>
        <v-toolbar color="primary" dark>Instructions</v-toolbar>
        <v-card-text>
          <div class="text-h5 pa-12" v-html="msg"></div>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text @click="dialog.value = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>
<script>
export default {
  props: ["section"],
  data() {
    return {
      initialMsg:
        "These cards are available immediately when somone subscribes to your Success Deck, with the first card on top.",
      contentMsg:
        "The first card is available immediately when someone subscribes to your Success Deck, with the remining cards sent according to the frequency you define in Properties on the right.",
      adHocMsg:
        "Each of these cards is sent and inserted in your subscriber's Success Deck when you click on the associated &quot;Send Now&quot; button.",
    };
  },
  computed: {
    msg() {
      let msg = this.initialMsg;
      switch (this.section) {
        case "initial":
          msg = this.initialMsg;
          break;
        case "content":
          msg = this.contentMsg;
          break;
        case "adHoc":
          msg = this.adHocMsg;
      }
      return msg;
    },
  },
};
</script>
