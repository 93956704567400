<template>
  <v-container id="regular-tables-view" fluid tag="section">
    <v-row>
      <v-col md="8">
        <!-- INITIAL CONTENT -->
        <v-row class="table-title">
          <v-col>
            <h3 class="mt-3">
              Introduction Cards
              <DialogCardInstructions
                section="initial"
              ></DialogCardInstructions>
            </h3>
          </v-col>
          <v-col>
            <v-btn
              v-if="!overSubscriberLimit"
              small
              @click="goToAddContentPage('Initial Content')"
              color="secondary"
            >
              Add Card
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-sheet
              icon="mdi-clipboard-text"
              icon-small
              title="Simple Table"
              color="accent"
            >
              <v-simple-table>
                <thead>
                  <tr>
                    <th :width="col1width" class="primary--text">Order</th>
                    <th :width="col2width" class="primary--text">Name</th>

                    <th class="primary--text"></th>
                    <th class="text-right primary--text"></th>
                  </tr>
                </thead>

                <tbody>
                  <tr v-for="(item, index) in initialContents" :key="index" :class="'row-'+ item.status">
                    <template>
                      <td>
                        <div class="change-item-order">
                          <v-text-field
                            v-if="displayedItems[item.id]"
                            class="order-input"
                            type="number"
                            v-model="item.order"
                          />
                          <v-btn
                            icon
                            @click="moveUp(item, initialContents)"
                            v-if="index != 0"
                          >
                            <v-icon>mdi-arrow-up</v-icon>
                          </v-btn>
                          <v-btn
                            icon
                            @click="moveDown(item, initialContents)"
                            v-if="index != initialContents.length - 1"
                          >
                            <v-icon>mdi-arrow-down</v-icon>
                          </v-btn>
                          <v-btn
                            v-if="displayedItems[item.id]"
                            icon
                            @click="reorderItem(item)"
                          >
                            <font-awesome-icon icon="save" />
                          </v-btn>
                        </div>
                      </td>

                      <td>
                        <div
                          class="link"
                          @click="goToEditor(item.id)"
                          v-html="item.name || 'Card #1'"
                        />
                       
                      </td>
                      <td></td>
                      <td class="text-right">
                        
                        <v-btn
                        v-if="item.status =='hidden'"
                          @click="
                            unhideItem({
                              programId: program.id,
                              programItemId: item.id,
                            })
                          "
                          color="dark"
                          icon
                        >
                          <v-icon>mdi-eye-off-outline</v-icon>
                        </v-btn>
                        <v-btn
                        v-if="item.numShares > 0 &&  item.status =='active'"
                          @click="
                            hideItem({
                              programId: program.id,
                              programItemId: item.id,
                            })
                          "
                          color="primary"
                          icon
                        >
                          <v-icon>mdi-eye-outline</v-icon>
                        </v-btn>
                        <v-btn
                         v-if="item.numShares == 0"
                          @click="
                            removeItem({
                              programId: program.id,
                              programItemId: item.id,
                            })
                          "
                          color="primary"
                          icon
                        >
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>


                      </td>
                    </template>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-sheet>
          </v-col>
        </v-row>

        <!-- PROGRAM CONTENT -->
        <v-row class="table-title" name="Program Content">
          <v-col>
            <h3 class="mt-3">
              Scheduled Content Cards
              <DialogCardInstructions section="content" />
            </h3>
          </v-col>
          <v-col>
            <v-btn
              v-if="!overSubscriberLimit"
              @click="goToAddContentPage('Program Content')"
              small
              color="secondary"
            >
              Add Card
            </v-btn>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-sheet
              icon="mdi-clipboard-text"
              icon-small
              title="Program Content"
              color="accent"
            >
              <v-simple-table>
                <thead>
                  <tr>
                    <th :width="col1width" class="primary--text order-col">
                      Order
                    </th>
                    <th :width="col2width" class="primary--text">Name</th>
                    <th class=""></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in programContents" :key="index">
                    <template>
                      <td>
                        <div class="change-item-order">
                          <v-text-field
                            v-if="displayedItems[item.id]"
                            class="order-input"
                            type="number"
                            v-model="item.order"
                          />
                          <v-btn
                            icon
                            @click="moveUp(item, programContents)"
                            v-if="index != 0"
                          >
                            <v-icon>mdi-arrow-up</v-icon>
                          </v-btn>
                          <v-btn
                            icon
                            @click="moveDown(item, programContents)"
                            v-if="index != programContents.length - 1"
                          >
                            <v-icon>mdi-arrow-down</v-icon>
                          </v-btn>
                          <v-btn
                            v-if="displayedItems[item.id]"
                            icon
                            @click="reorderItem(item)"
                          >
                            <font-awesome-icon icon="save" />
                          </v-btn>
                        </div>
                      </td>
                      <td>
                        <div
                          class="link"
                          @click="goToEditor(item.id)"
                          v-html="
                            item.name == '' ? 'Card #' + index : item.name
                          "
                        />
                      </td>

                     
                      <td class="text-right">
                        <v-btn
                        v-if="item.status =='hidden'"
                          @click="
                            unhideItem({
                              programId: program.id,
                              programItemId: item.id,
                            })
                          "
                          color="dark"
                          icon
                        >
                          <v-icon>mdi-eye-off-outline</v-icon>
                        </v-btn>
                        <v-btn
                        v-if="item.numShares > 0 &&  item.status =='active'"
                          @click="
                            hideItem({
                              programId: program.id,
                              programItemId: item.id,
                            })
                          "
                          color="primary"
                          icon
                        >
                          <v-icon>mdi-eye-outline</v-icon>
                        </v-btn>
                        <v-btn
                         v-if="item.numShares == 0"
                          @click="
                            removeItem({
                              programId: program.id,
                              programItemId: item.id,
                            })
                          "
                          color="primary"
                          icon
                        >
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>

                      </td>
                    </template>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-sheet>
          </v-col>
        </v-row>
        <v-row class="table-title">
          <v-col>
            <h3 class="mt-3">
              Adhoc Content Cards
              <DialogCardInstructions section="adHoc"></DialogCardInstructions>
            </h3>
          </v-col>
          <v-col>
            <v-btn
              v-if="!overSubscriberLimit"
              small
              @click="goToAddContentPage('Adhoc Content')"
              color="secondary"
            >
              Add Card
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-sheet
              icon="mdi-clipboard-text"
              icon-small
              title="Simple Table"
              color="accent"
            >
              <v-simple-table>
                <thead>
                  <tr>
                    <th :width="col1width" class="primary--text">Date</th>
                    <th :width="col2width" class="primary--text">Name</th>

                    <th class="text-right primary--text"></th>
                  </tr>
                </thead>

                <tbody>
                  <tr v-for="(item, index) in programItems" :key="index">
                    <template v-if="item.contentType == 'Adhoc Content'">
                      <td></td>
                      <td>
                        <div
                          class="link"
                          @click="goToEditor(item.id)"
                          v-html="item.name || 'Card #' + index"
                        />
                      </td>

                      <td class="text-right">
                        <v-btn
                          outlined
                          x-small
                          color="primary"
                          @click="sendNow(item.id)"
                        >
                          Send Now
                        </v-btn>
                        &nbsp;&nbsp;

                        <v-btn
                        v-if="item.status =='hidden'"
                          @click="
                            unhideItem({
                              programId: program.id,
                              programItemId: item.id,
                            })
                          "
                          color="dark"
                          icon
                        >
                        <v-icon>mdi-eye-off-outline</v-icon>
                        </v-btn>
                        <v-btn
                        v-if="item.numShares > 0 &&  item.status =='active'"
                          @click="
                            hideItem({
                              programId: program.id,
                              programItemId: item.id,
                            })
                          "
                          color="primary"
                          icon
                        >
                         
                          <v-icon>mdi-eye-outline</v-icon>
                        </v-btn>
                        <v-btn
                         v-if="item.numShares == 0"
                          @click="
                            removeItem({
                              programId: program.id,
                              programItemId: item.id,
                            })
                          "
                          color="primary"
                          icon
                        >
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>

                      </td>
                    </template>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-sheet>
          </v-col>
        </v-row>
      </v-col>
      <v-col md="4" class="d-flex flex-column align-items-center">
        <v-sheet class="mx-auto mb-5" style="width: 100%" tile v-if="program">
          <v-toolbar color="cyan" dark>
            <v-toolbar-title>
              <div
                class="text-left float-left pull-left"
                style="padding-right: 155px"
              >
                Properties
              </div>
            </v-toolbar-title>
            <span>
              <edit-program-properties-dialog
                :programId="program.id"
                :programData="program"
                class="float-right pull-right"
              />
            </span>

            <v-spacer></v-spacer>
          </v-toolbar>

          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Success Deck Name</v-list-item-title>

              <v-list-item-subtitle
                class="mt-1"
                style="text-transform: capitalize"
              >
                {{ program.name }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Status</v-list-item-title>

              <v-list-item-subtitle
                class="mt-1"
                style="text-transform: capitalize"
              >
                {{ program.status }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Content Frequency</v-list-item-title>
              <v-list-item-subtitle>
                <v-chip :outlined="!program.fSunday">Sun</v-chip>
                <v-chip :outlined="!program.fMonday">Mon</v-chip>
                <v-chip :outlined="!program.fTuesday">Tue</v-chip>
                <v-chip :outlined="!program.fWednesday">Wed</v-chip>
                <v-chip :outlined="!program.fThursday">Thu</v-chip>
                <v-chip :outlined="!program.fFriday">Fri</v-chip>
                <v-chip :outlined="!program.fSaturday">Sat</v-chip>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line v-if="program.frequency == 'weekly'">
            <v-list-item-content>
              <v-list-item-title>Content Day</v-list-item-title>
              <v-list-item-subtitle>
                {{ detailText["weekly"][program.frequencyDetail] }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line v-if="program.frequency == 'monthly'">
            <v-list-item-content>
              <v-list-item-title>Content Day</v-list-item-title>
              <v-list-item-subtitle>
                {{ program.frequencyDetail }}
                <sup> {{ daySuffix[program.frequencyDetail % 10] }} </sup>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Send Time</v-list-item-title>
              <v-list-item-subtitle>
                {{ tformat(program.frequencyTime) }}   {{ tzformat(program.frequencyTimeZone) }}  
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Learn More Link</v-list-item-title>
              <v-list-item-subtitle>
                {{ program.moreUrl }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-sheet>
        <v-btn class="align-self-center" @click="handlePreview">Preview</v-btn>
      </v-col>
    </v-row>
    <div class="py-3" />
    <ConfirmDlg ref="confirm" />
    <PreviewDecksDialog ref="preview" :contentIds="contentIds" />
  </v-container>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import EditProgramPropertiesDialog from "../components/EditProgramPropertiesDialog.vue";
import PreviewDecksDialog from "../components/PreviewDecksDialog.vue";
import DialogCardInstructions from "../components/DialogCardInstructions.vue";

export default {
  methods: {
    ...mapActions({
      getContent: "getProgramContentItems",
      removeProgramContentItem: "deleteProgramContentItem",
      hideProgramContentItem: "hideProgramContentItem",
      unhideProgramContentItem: "unhideProgramContentItem",
      update: "updateProgramContentItem",
      switchItems: "switchProgramContentItems",
      getProgram: "axProgram",
      createProgramItem:"addProgramContentItem"
    }),
    ...mapMutations({
      resetProgramItem: "resetCurrentProgramItem",
    }),
    handlePreview() {
      this.contentIds = this.getContentIds();
      this.$refs.preview.open();
    },
    moveUp(item, data) {
      console.log(this.programItems);
      let index = -1;
      data.forEach((program, i) => {
        if (program === item) index = i;
      });

      if (index < 1) return;

      const prevItem = data[index - 1];
      this.switchItems({
        programId: this.programId,
        fItem: item,
        sItem: prevItem,
      });
    },
    getContentIds() {
      let initialContentIds = [];
      let programContentIds = [];
      let adhocContentIds = [];

      this.programItems.map((item) => {
        switch (item.contentType) {
          case "Initial Content":
            initialContentIds.push(item.id);
            break;
          case "Program Content":
            programContentIds.push(item.id);
            break;
          case "Adhoc Content":
            adhocContentIds.push(item.id);
            break;
        }
      });

      return initialContentIds.concat(programContentIds, adhocContentIds);
    },
    moveDown(item, data) {
      let index = data.length;
      data.forEach((program, i) => {
        if (program === item) index = i;
      });

      if (index >= data.length - 1) return;

      const nextItem = data[index + 1];

      this.switchItems({
        programId: this.programId,
        fItem: item,
        sItem: nextItem,
      });
    },
    async removeItem(payload) {
      if (
        await this.$refs.confirm.open(
          "Confirm",
          "Are you sure to delete this item?"
        )
      ) {
        this.removeProgramContentItem(payload);
      }
    },
    async hideItem(payload) {
      if (
        await this.$refs.confirm.open(
          "Confirm",
          "Are you sure to hide this item?"
        )
      ) {
        this.hideProgramContentItem(payload);
      }
    },
    async unhideItem(payload) {
      if (
        await this.$refs.confirm.open(
          "Confirm",
          "Are you sure to unhide this item?"
        )
      ) {
        this.unhideProgramContentItem(payload);
      }
    },
    sorted(programItems) {
      if (!programItems) return [];
      return programItems.sort((a, b) => {
        if (a.order > b.order) return 1;
        if (a.order < b.order) return -1;
        return 0;
      });
    },
    async sendNow(id) {
      if (await this.$refs.confirm.open("Confirm", "Are you sure?")) {
        //this.deleteRecord();
        console.debug(id)
      }
    },
    reorderItem(item) {
      delete item.__typename;
      this.update({
        ...item,
        order: parseInt(item.order),
      });
      this.toggleShowingInput(item);
      // this.sortedProgramItems = this.sorted(this.programItems)
    },
    toggleShowingInput(item) {
      if (this.displayedItems[item.id]) {
        this.displayedItems[item.id] = false;
      } else {
        this.displayedItems[item.id] = true;
      }
    },
    async goToAddContentPage(contentType) {
      this.resetProgramItem();
      const payload = {
        programId: this.programId,
        contentType: contentType,
        order: 0
      }
     const pi = await this.createProgramItem(payload)
     this.goToEditor(pi.createProgramItem.id)

     
    },
    goToEditor(itemId) {
      this.$router.push(
        `/app/programs/${this.program.id}/content/${itemId}/edit`
      );
    },
    tformat(e) {
      const tparts = e.split(":");
      let ampm = " PM";
      let hours = 0
      if (parseInt(tparts[0]) > 12) {
        hours = tparts[0] - 12;
        ampm = " PM";
      }
      if (parseInt(tparts[0]) < 12) {
        hours = tparts[0]
        ampm = " AM";
      }
      if (parseInt(tparts[0]) == 0) {
        hours =  12;
        ampm = " AM";
      }

      return  hours + ":" + tparts[1] + ampm;
    },
    tzformat(e){
      console.log(e, "TZ VALUE")
      switch(e){
        case "-04:00":
          return "EST"
        case "-05:00":
          return "CST"
        case "-06:00":
          return "MST"
        case "-07:00":
          return "PST"
        case "-08:00":
          return "PST"
        case "-09:00":
          return "PST"
        default:
          return "HDT"
      }
    }
  },
  components: {
    ConfirmDlg: () => import("../components/ConfirmDialog"),
    EditProgramPropertiesDialog,
    PreviewDecksDialog,
    DialogCardInstructions,
  },
  name: "CuratedContent",
  data() {
    return {
      contentIds: [],
      col1width: "75px",
      col2width: "375px",
      displayedItems: {},
      detailText: [],
      daySuffix: [
        "th",
        "st",
        "nd",
        "rd",
        "th",
        "th",
        "th",
        "th",
        "th",
        "th",
        "th",
      ],
    };
  },
  computed: {
    overSubscriberLimit() {
      return this.currentCoach.numContentItems >=
        this.currentCoach.planMaxContentItems
        ? true
        : false;
    },
    ...mapGetters({
      currentCoach: "getCurrentCoach",
      program: "getCurrentProgram",
      programItems: "getProgramItems",
    }),
    sortedProgramItems() {
      return this.programItems && this.programItems.length
        ? this.sorted(this.programItems)
        : [];
    },
    programContents() {
      return this.sortedProgramItems.filter(
        (item) => item.contentType == "Program Content"
      );
    },
    initialContents() {
      return this.sortedProgramItems.filter(
        (item) => item.contentType == "Initial Content"
      );
    },
    programId() {
      return this.$route.params.programId;
    },
  },
  mounted() {
    this.detailText["weekly"] = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    this.getProgram({ program: { id: this.programId } });
    this.getContent({ programId: this.programId });

    for (const item of this.programItems) {
      // keeps track of which item 'order component' is displayed
      this.$set(this.displayedItems, item.id, false);
      // this.displayedItems[item.id] = false
    }
    // this.sortedProgramItems = this.sorted(this.programItems)
  },
};
</script>

<style scoped>
.up-and-down-arrow {
  background: url("/img/up-and-down-arrow.png");
  width: 20px;
  height: 20px;
  background-size: contain;
}

.change-item-order {
  width: 100%;
  display: flex;
}
.change-item-order span {
  display: inline-block;
  margin: auto;
}
.order-col {
  width: 70px;
}
.order-input {
  width: 30px;
  display: inline-block;
}
.table-title .col:nth-child(2) .v-btn {
  float: right;
  margin-right: 10px;
}
.row {
  margin-top: 0;
}

.table-title:nth-child(1) {
  margin-top: 0;
}

.table-title {
  margin-top: 3em;
}
.toggle-password-reveal {
  cursor: pointer;
}
.link {
  text-decoration: underline;
  cursor: pointer;
}
.v-application a {
  color: #9e2263;
}
.v-application .cyan {
  background-color: #9e2263 !important;
  border-color: #9e2263 !important;
}
.v-application .primary--text {
  color: #9e2263 !important;
  caret-color: #9e2263 !important;
}
.row-hidden{
  background-color: #ddd;
  font-style: italic;
  color: #777;
}
</style>
